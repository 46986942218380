import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';

//validation library
import * as Yup from 'yup';

// library components
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

// core components
import TextArea from 'components/CustomFormComponents/TextArea';
import TextInput from 'components/CustomFormComponents/TextInput';
import ButtonSpinner from 'components/ButtonSpinner';
import Spinner from 'components/Spinner';

import MaterialDate from 'components/CustomFormComponents/MaterialDate';
import { createDeal, createCompany, updateDeal } from 'redux/actions/staffingActions';
import { selectCompanies, selectDeal } from 'redux/selectors/staffing/getDeals';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import { some } from 'lodash';
import CustomSearchableSelect from 'components/CustomFormComponents/CustomSearchableSelect';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px', // Adjust this width as needed
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
  addCompanyButton: {
    height: 30,
    width: 30,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  addCompanyButtonGrey: {
    height: 30,
    width: 30,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    top: -8,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
      color: '#fff',
    },
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },

  selectContainer: {
    marginBottom: 0,
  },
}));

const AddDealForm = props => {
  const { closeDialog } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { dealToUpdateId } = useStaffingSelector();

  const [isSubmittingCompanyName, setIsSubmittingCompanyName] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddCompanyEnabled, setIsAddCompanyEnabled] = useState(false);

  const getAllCompanies = selectCompanies();
  const { companies } = useSelector(state => getAllCompanies(state));

  //Get the Deal Info from the Store
  const getDeal = selectDeal();
  const deal = useSelector(state => getDeal(state, dealToUpdateId));

  const formInitialValues = {
    dealName: deal ? deal.dealName : '',
    companyId: deal ? deal.companyId : '',
    companyName: deal ? deal.companyName : '',
    contactName: deal ? deal.contactName : '',
    contractStartDate: deal ? deal.contractStartDate : '',
    contractEndDate: deal ? deal.contractEndDate || '' : '',
    contactEmail: deal ? deal.contactEmail : '',
    amount: deal ? deal.amount : 0,
    hubspotDealId: deal ? deal.hubspotDealId : '',
    projectBriefTemplateId: deal ? deal.projectBriefTemplateId : '',
    notes: deal ? deal.notes : '',
  };

  const formSchema = Yup.object().shape({
    dealName: Yup.string().required('Required'),
    companyId: Yup.string().required('Required'),
    companyName: Yup.string(),
    contactName: Yup.string().required('Required'),
    contractStartDate: Yup.string()
      .test('is-date', 'must be a valid date', function (value) {
        if (!value) return true;
        return moment(value, 'YYYY-MM-DD').isValid();
      })
      .nullable(),
    contractEndDate: Yup.string()
      .test(
        'end-date-is-after-start-date',
        "End Date can't be set before the Start Date",
        function (value) {
          if (!value || !this.parent.contractStartDate) return true;
          return this.parent.contractStartDate < value;
        },
      )
      .test('is-date', 'must be a valid date', function (value) {
        if (!value) return true;
        return value && moment(value, 'YYYY-MM-DD').isValid();
      })
      .nullable(),
    contactEmail: Yup.string().email().required('Required'),
    projectBriefTemplateId: Yup.string(),
    amount: Yup.number()
      .positive('Required')
      .required('Required')
      .min(
        deal?.projectsValuesSum || 1,
        deal ? "Can't be less than the sum of existing Projects' Contract Value" : 'Required',
      ),
    hubspotDealId: Yup.string().when('companyId', {
      is: companyId => !isPublic(companyId),
      then: Yup.string().required(
        'HubSpot Deal ID is required when Company is not Voltage Control',
      ),
    }),
    notes: Yup.string(),
  });

  const isPublic = companyId => {
    const company = companies.find(company => company.value === companyId);
    return company && company.name === 'Voltage Control';
  };

  const handleFormSubmit = values => {
    setIsSubmitting(true);

    const successCallback = () => {
      setIsSubmitting(false);
      closeDialog();
    };

    setIsSubmitting(true);

    const { companyName, ...formValues } = values; //remove the un-needed 'companyName' prop.

    if (companyName === 'Mural Playmakers') {
      formValues.isEOD = true;
    }

    //If in Edit mode, dispatch to the 'updateDeal' action thunk
    if (dealToUpdateId) {
      dispatch(updateDeal(dealToUpdateId, { ...formValues }, successCallback));
      return;
    }

    //if not, dispatch to the 'createDeal' action thunk
    dispatch(createDeal({ ...formValues }, successCallback));
    closeDialog();
  };

  const handleAddNewCompanyClick = (companyName, setFieldValue) => {
    if (isAddCompanyEnabled && companyName && companyName.length > 3) {
      //check if the company name already exists
      if (some(companies, { name: companyName })) {
        dispatch(enqueueErrorSnackbar('Company already exists. Please enter another Company name'));
        return;
      }

      setIsSubmittingCompanyName(true);

      // Add Company to List
      dispatch(
        createCompany(companyName, newCompanyId => {
          setIsAddCompanyEnabled(false);
          setIsSubmittingCompanyName(false);
          setFieldValue('companyName', companyName);
          setFieldValue('companyId', newCompanyId);
        }),
      );
      return;
    }

    setIsAddCompanyEnabled(true);
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={async values => {
        handleFormSubmit(values);
      }}
    >
      {({ submitForm, resetForm, values, setFieldValue, errors, touched, submitCount }) => (
        <Form style={{ paddingBottom: 20 }}>
          {console.log('formValues', values)}
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="dealName"
                type="string"
                label="Deal Name"
                className="mb-4"
                required
              />
            </Grid>

            <Grid item xs={5} className="pr-1">
              {isAddCompanyEnabled ? (
                <TextInput
                  name="companyName"
                  type="string"
                  label="Add New Company"
                  className="mb-0"
                  required
                  onClear={() => {
                    setIsAddCompanyEnabled(false);
                    setFieldValue('companyName', '');
                  }}
                />
              ) : (
                <div>
                  <CustomSearchableSelect
                    label="Company"
                    name="companyId"
                    disabled={Boolean(dealToUpdateId)}
                    required
                    options={companies}
                    valueOverride={{
                      name: values.companyName || '',
                      value: values.companyId || '',
                    }}
                    customOnChange={(event, option) => {
                      if (option?.name && option?.value) {
                        setFieldValue('companyName', option.name);
                        setFieldValue('companyId', option.value);
                      } else {
                        setFieldValue('companyName', '');
                        setFieldValue('companyId', '');
                      }
                    }}
                    customStyles={{
                      container: classes.selectContainer,
                      label: classes.inputLabel,
                    }}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={1} container className="pl-0 justify-content-end">
              <Button
                size="small"
                variant="text"
                disabled={isSubmittingCompanyName || dealToUpdateId}
                className={cx({
                  'mt-4': !isAddCompanyEnabled,
                  'mt-5': isAddCompanyEnabled,
                  [classes.addCompanyButtonGrey]:
                    isAddCompanyEnabled && (values.companyName || '').length <= 3,
                  [classes.addCompanyButton]:
                    (values.companyName || '').length > 3 || !isAddCompanyEnabled,
                })}
                onClick={() => {
                  handleAddNewCompanyClick(values.companyName, setFieldValue);
                }}
              >
                {isAddCompanyEnabled ? (
                  isSubmittingCompanyName ? (
                    <Spinner size={24} />
                  ) : (
                    <DoneIcon />
                  )
                ) : (
                  <AddIcon />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput name="contactName" type="string" label="Contact Name" required />
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <MaterialDate name="contractStartDate" label="Contract Start Date" />
              </Grid>

              <Grid item xs={6}>
                <MaterialDate name="contractEndDate" type="date" label="Contract End Date" />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="contactEmail"
                type="string"
                label="Contact Email"
                className="mb-4"
                required
              />
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  name="amount"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  label="Est Deal Value"
                  className="mb-4"
                  required
                />
              </Grid>

              {values.companyName !== 'Voltage Control' && (
                <Grid item xs={6}>
                  <TextInput
                    name="hubspotDealId"
                    label="Hubspot Deal ID"
                    className="mb-4"
                    required={!isPublic(values.companyId)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <TextInput
            name="projectBriefTemplateId"
            label="Project Brief Template Id"
            className="mb-4"
            helperText="Add a project brief template id if you want Projects on this Deal to use a custom project brief."
          />

          <TextArea
            name="notes"
            type="text"
            label="Notes"
            multiline
            fullWidth
            rows={4}
            className="mb-4"
          />

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDealForm;
