import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../types/notifierTypes';

export const enqueueSnackbar = (notification, type) => {
  const key = notification.options && notification.options.key;

  if (type) {
    notification.options.type = type;
  }

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const enqueueErrorSnackbar =
  (message, options = {}) =>
  dispatch => {
    dispatch(
      enqueueSnackbar({
        message,
        options: { variant: 'error', autoHideDuration: 5000, ...options },
      }),
    );
  };

export const enqueueSuccessSnackbar =
  (message = 'Success!', options = {}) =>
  dispatch => {
    dispatch(
      enqueueSnackbar({
        message,
        options: { variant: 'success', ...options },
      }),
    );
  };

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});
