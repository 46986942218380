import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';

//staffing components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

//actions & selectors
import { openCreateContractorDialog, deleteContractorUser } from 'redux/actions/staffingActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import FlagIcon from '@material-ui/icons/Flag';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import TagIcon from '@material-ui/icons/LocalOffer';
import TagChip from 'components/TagChip';

const useStyles = makeStyles(({ theme }) => ({
  rowView: {
    display: 'flex',
    marginBottom: 10,
  },
  noteIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  tagIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  flagIcon: {
    width: 20,
    color: 'rgba(226, 53, 30, 1)',
  },
  noteText: {
    fontSize: 14,
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.87)',
    marginLeft: 5,
  },
}));

const AboutContractorCard = ({ contractor }) => {
  const {
    email,
    displayPhone,
    displayEmail,
    displayStatus,
    displayName,
    type,
    hourlyRate,
    contractEndDate,
    tags,
    notes,
    flagComments,
  } = contractor;

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const db = useFirestore();
  const [contractorUser, setContractorUser] = useState(null);

  useEffect(() => {
    db.collection('users')
      .where('email', '==', email)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size === 1) {
          setContractorUser(querySnapshot.docs[0].data());
        }
      })
      .catch(e => {
        dispatch(enqueueErrorSnackbar('Error fetching staffing flags for contractor'));
        console.error(e);
      });
  }, []);

  const deleteContractorProfile = () => {
    confirm({
      description: `You are about to delete ${displayName}'s profile. This action is permanent. Are you sure?.`,
    }).then(() => {
      dispatch(
        deleteContractorUser(contractor.id, () => {
          history.push('/admin/staffing/contractors');
        }),
      );
    });
  };

  // MENU CONTROL OPTIONS
  const menuOptions = [
    {
      title: 'Edit Profile',
      onItemClick: () => {
        dispatch(openCreateContractorDialog(true, contractor.id));
      },
    },
    {
      title: 'Delete',
      onItemClick: deleteContractorProfile,
    },
  ];

  const formattedContractEndDate =
    contractEndDate && moment(contractEndDate).isAfter(moment())
      ? moment(contractEndDate).format('MMMM D, YYYY')
      : null;

  return (
    <div
      className="d-flex justify-content-between flex-wrap shadow rounded p-3 border position-relative w-100"
      style={{ minWidth: '300px', minHeight: '220px' }}
    >
      <div className="flex-grow-1">
        <h6 className="text-uppercase text-secondary">About Staff</h6>
        <h4 className="font-weight-bold mb-1">{displayName}</h4>

        <div className="mb-1">
          <p className="text-dark mb-0">{type}</p>
          <p className="text-dark mb-0">
            {displayEmail}
            {displayPhone && ` | ${displayPhone}`}
          </p>
          <p className="text-dark mb-0">
            {formattedContractEndDate
              ? `Contract ends: ${formattedContractEndDate}`
              : 'No active contract'}
          </p>
        </div>

        <div className={classes.rowView}>
          <DescriptionIcon className={classes.noteIcon} />
          <Typography className={classes.noteText}>{notes || 'None'}</Typography>
        </div>
        <div className={classes.rowView}>
          <FlagIcon className={classes.flagIcon} />
          <Typography className={classes.noteText}>{flagComments || 'None'}</Typography>
        </div>

        {tags && tags.length > 0 && (
          <div className={classes.rowView}>
            <TagIcon className={classes.tagIcon} />
            <div>
              {tags.map((tag, idx) => (
                <TagChip key={idx} label={tag} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-grow-1">
        <div className="d-flex flex-column flex-grow-1 mr-2">
          <h6 className="text-uppercase text-secondary">Type</h6>
          <h4 className="font-weight-bold m-0">{type}</h4>
        </div>

        <div className="d-flex flex-column flex-grow-1 mr-2">
          <h6 className="text-uppercase text-secondary">Status</h6>
          <h4 className="font-weight-bold m-0">{displayStatus}</h4>
          {contractorUser && (!contractorUser.vcContractor || !contractorUser.staffingUser) && (
            <div className="text-danger h6 font-weight-bold">Missing Staffing Flags</div>
          )}
        </div>
      </div>

      <SelectMenu options={menuOptions} />
    </div>
  );
};

export default AboutContractorCard;
