import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// library components
import { Formik, Form } from 'formik';
import { Button, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

//validation library
import * as Yup from 'yup';
import moment from 'moment';
import { some, values, trimEnd, map, includes } from 'lodash';

import {
  PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS,
  PROJECT_LOCATION_TYPES,
  PROJECT_SERVICE_SELECT_OPTIONS,
} from 'constants/staffing';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import {
  selectProject,
  selectProjectMethodologies,
  selectDealsDropdown,
} from 'redux/selectors/staffing/getProject';
import {
  createProject,
  createProjectMethodology,
  updateProject,
  setDealsFilterPayload,
} from 'redux/actions/staffingActions';
import { selectDeal } from 'redux/selectors/staffing/getDeals';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import TextInput from 'components/CustomFormComponents/TextInput';
import MaterialDate from 'components/CustomFormComponents/MaterialDate';
import MaterialTime from 'components/CustomFormComponents/MaterialTime';
import CustomCheckbox from 'components/CustomFormComponents/CustomCheckbox';
import TextArea from 'components/CustomFormComponents/TextArea';
import ButtonSpinner from 'components/ButtonSpinner';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import Spinner from 'components/Spinner';

import { PROJECT_LOCATION_TYPES_SELECT_OPTIONS, PROJECT_STATUS_STATES } from 'constants/staffing';
import { formatPrice } from 'utils/formatPrice';
import CustomSearchableSelect from 'components/CustomFormComponents/CustomSearchableSelect';

const useStyles = makeStyles(theme => ({
  // dialogActions: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   marginTop: theme.spacing(4),
  // },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px', // Adjust this width as needed
  },

  staticInfoViewLabel: {
    // lineHeight: '30px',
    // fontSize: 12,
    // // fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  staticInfoView: {
    minHeight: 32,
    border: '1px dashed #C9C9C9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 5,
  },
  staticInfoViewTitle: {
    lineHeight: '30px',
    fontSize: 14,
    fontWeight: '400',
    color: '#5E5E5E',
  },
  addMethodologyButton: {
    height: 30,
    width: 30,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    top: 26,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  addMethodologyButtonGrey: {
    height: 30,
    width: 30,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    top: 26,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
      color: '#fff',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },

  inputLabel: {
    // lineHeight: '30px',
    // fontSize: 12,
    // fontWeight: '300',
  },
  selectContainer: {
    marginBottom: 0,
  },

  checkboxLabel: {
    lineHeight: '30px',
    fontSize: 12,
    fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
}));

const AddProjectToDealForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusArr = values(PROJECT_STATUS_STATES);

  const [isSubmittingAddProjectMethodology, setIsSubmittingAddProjectMethodology] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddMethodologyEnabled, setIsAddMethodologyEnabled] = useState(false);

  //Get the Project Methodologies from the Store
  const getAllProjectMethodologies = selectProjectMethodologies();
  const { projectMethodologies } = useSelector(state => getAllProjectMethodologies(state));

  //Get the Deals
  const getAllDealsDropdown = selectDealsDropdown();
  const { deals } = useSelector(state => getAllDealsDropdown(state));

  const { projectToUpdateId, dealToUpdateId } = useStaffingSelector();
  const getProject = selectProject();
  const isUpdate = Boolean(projectToUpdateId);

  //Get the Project Info from the Store
  const project = useSelector(state => getProject(state, projectToUpdateId));

  //Get the Deal Info from the Store
  const getDeal = selectDeal();
  const deal = useSelector(state => getDeal(state, dealToUpdateId));

  // TODO: build this into the deal and project selectors...
  const isPublic = deal?.company?.companyName === 'Voltage Control';

  const defaultCalendarEventRecurrenceType = PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS.find(
    option => option.value === 'DAILY',
  ).value;

  //Form Initial Values
  const formInitialValues = {
    calendarEventRecurrenceType: project
      ? project.calendarEventRecurrenceType || defaultCalendarEventRecurrenceType
      : defaultCalendarEventRecurrenceType,
    contactName: project ? project.contactName : '',
    contactEmail: project ? project.contactEmail : '',
    contractValue: project ? project.contractValue : (deal?.amountLeft ?? 0),
    endDate: project ? project.endDate : '',
    endTime: project ? project.endTime : '15:00:00',
    eventName: project ? project.eventName : '',
    isFree: project ? project.isFree : false,
    locationType: project ? project.locationType : '',
    notes: project ? project.notes : '',
    projectMethodologyId: project ? project.projectMethodologyId : '',
    serviceType: project ? project.serviceType : '',
    startDate: project ? project.startDate : '',
    startTime: project ? project.startTime : '10:00:00',
    venue: project ? project.venue : '',
    zoomLink: project ? project.zoomLink : '',
  };

  // get the project status with '-error' stripped off if it is there
  const nonErrorStatus = project?.status
    ? project.status.endsWith('-error')
      ? trimEnd(project.status, '-error')
      : project.status
    : null;

  const isPending = () =>
    statusArr.indexOf(nonErrorStatus) === statusArr.indexOf(PROJECT_STATUS_STATES.PENDING);
  const isScheduled = () =>
    statusArr.indexOf(nonErrorStatus) > statusArr.indexOf(PROJECT_STATUS_STATES.ADDING_CALENDAR);
  const dateRequiredWhenScheduled = value => !isUpdate || isPending() || (value && isScheduled());
  const isValidTime = value => !value || (value && moment(value, 'HH:mm:ss').isValid());

  // Yup schema
  const formSchema = Yup.object().shape({
    locationType: Yup.string().required('Required'),
    contactName: Yup.string(),
    contactEmail: Yup.string().email('Contact Email should be a valid email'),
    zoomLink: Yup.string()
      .url('Zoom Link must be a valid URL')
      .when('locationType', {
        is: locationType => locationType !== 'in_person',
        then: Yup.string().required('Required when the location type is Virtual or Hybrid'),
        otherwise: Yup.string().notRequired(),
      }),
    venue: Yup.string().when('locationType', {
      is: locationType => locationType !== 'virtual',
      then: Yup.string().required('Required when the location type is In-Person or Hybrid'),
      otherwise: Yup.string().notRequired(),
    }),
    eventName: Yup.string().required('Required'),
    serviceType: Yup.string()
      .required('Required')
      .test(
        'allowed-values-when-public',
        'Public projects must be either "Training" or "Certification"',
        value => projectToUpdateId || !isPublic || ['Training', 'Certification'].includes(value),
      ),
    projectMethodologyId: Yup.string().required('Required'),
    startDate: Yup.date()
      .test(
        'required-when-public',
        'Required to create a public project',
        value => (isPublic && value) || !isPublic,
      )
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      ),
    startTime: Yup.string()
      .test(
        'required-when-public',
        'Required to create a public project',
        value => (isPublic && value) || !isPublic,
      )
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      )
      .test('is-time', 'must be a valid time', value => isValidTime(value))
      .nullable(),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End Date can't be set before the Start Date")
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      ),
    endTime: Yup.string()
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      )
      .test('is-time', 'must be a valid time', value => isValidTime(value)),
    isFree: Yup.boolean(),
    contractValue: Yup.number()
      .max(
        (deal?.amountLeft || 0) + formInitialValues.contractValue,
        "Must be less than the Deal's remaining estimated value",
      )
      .min(0, 'Must be greater than or equal to 0'),
    notes: Yup.string(),
    calendarEventRecurrenceType: Yup.string().required('Required'),
  });

  //const handleFormSubmit = async (values, { setErrors }) => {
  const handleAddProject = async (values, { setErrors }) => {
    setIsSubmitting(true);

    delete values.projectMethodologyName;

    const successCallback = () => {
      setIsSubmitting(false);
      closeDialog();
    };

    const eventNames = map(deal.projects, project => project.eventName);
    if (!projectToUpdateId && includes(eventNames, values.eventName)) {
      setErrors({ eventName: 'Event Name must be unique per deal' });
      setIsSubmitting(false);
      return;
    }

    if (values.contactName && !values.contactEmail) {
      setErrors({ contactEmail: 'Contact Email is required when setting Contact Name' });
      setIsSubmitting(false);
      return;
    }

    if (values.contactEmail && !values.contactName) {
      setErrors({ contactName: 'Contact Name is required when setting Contact Name' });
      setIsSubmitting(false);
      return;
    }

    if (projectToUpdateId) {
      //if the DealId has been changed, pass the previousDealId to the updateProject thunk
      const previousDealId = project.dealId !== dealToUpdateId ? project.dealId : '';

      dispatch(
        updateProject(
          projectToUpdateId,
          { ...values, dealId: dealToUpdateId },
          successCallback,
          previousDealId,
        ),
      );
      return;
    }

    dispatch(createProject({ ...values, dealId: deal.id }, true, successCallback));
  };

  const handleAddNewMethodologyClick = (methodologyName, setFieldValue) => {
    if (isAddMethodologyEnabled && methodologyName && methodologyName.length > 3) {
      //check if the Methodology name already exists
      if (some(projectMethodologies, { name: methodologyName })) {
        dispatch(
          enqueueErrorSnackbar('Methodology already exists. Please enter another Methodology name'),
        );
        return;
      }

      setIsSubmittingAddProjectMethodology(true);

      // Add Methodology to List
      dispatch(
        createProjectMethodology(methodologyName, methodologyId => {
          setIsAddMethodologyEnabled(false);
          setIsSubmittingAddProjectMethodology(false);
          setFieldValue('projectMethodologyId', methodologyId);
          setFieldValue('projectMethodologyName', '');
        }),
      );
      return;
    }

    setIsAddMethodologyEnabled(true);
  };

  const handleEditDeal = () => {
    dispatch(
      setDealsFilterPayload({
        dealToUpdateId: '',
      }),
    );
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={handleAddProject}
    >
      {({ submitForm, resetForm, values, setFieldValue, errors, touched, submitCount }) => (
        <Form style={{ paddingBottom: 20 }}>
          {console.log('AddProjectToDealForm : values : ', values)}
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="eventName"
                label="Project Name"
                className=""
                // Project Name is used in initialize, schedule create assets, so don't allow editing
                disabled={isUpdate}
                required
                additionalStyles={{ label: classes.inputLabel }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomSearchableSelect
                label="Deal"
                name="dealId"
                customOnChange={(_, value) => {
                  dispatch(
                    setDealsFilterPayload({
                      dealToUpdateId: value.value,
                    }),
                  );
                }}
                placeholder=""
                required
                valueOverride={
                  deal
                    ? {
                        name: `${deal?.dealName} (${deal?.companyName})`,
                        value: deal?.id,
                      }
                    : null
                }
                disableClearable
                disabled={Boolean(projectToUpdateId || deal)}
                options={deals}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="contactName"
                type="string"
                label="Contact Name"
                helperText="When not provided, Create Assets will use Deal Contact Name"
                additionalStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomSelect
                label="Service Type"
                className="mb-4"
                name="serviceType"
                type="select"
                placeholder=""
                required
                // service type is used in initialize so don't allow editing
                disabled={isUpdate}
                selectOptions={PROJECT_SERVICE_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="contactEmail"
                type="string"
                label="Contact Email"
                helperText="When not provided, Create Assets will use Deal Contact Email"
                additionalStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>

            <Grid item xs={5} className="pr-1">
              {isAddMethodologyEnabled ? (
                <TextInput
                  name="projectMethodologyName"
                  type="string"
                  label="Methodology"
                  required
                  additionalStyles={{
                    label: classes.inputLabel,
                  }}
                  onClear={() => {
                    setIsAddMethodologyEnabled(false);
                    setFieldValue('projectMethodologyName', '');
                  }}
                  className="mb-0"
                />
              ) : (
                <CustomSelect
                  label="Methodology"
                  name="projectMethodologyId"
                  type="select"
                  placeholder=""
                  required
                  selectOptions={projectMethodologies}
                  customStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  className="mb-0"
                  // methodology is used in initialize so don't allow editing
                  disabled={isUpdate}
                />
              )}
            </Grid>

            <Grid item xs={1} container className="pl-0 justify-content-end">
              <Button
                size="small"
                variant="text"
                disabled={isSubmittingAddProjectMethodology || isUpdate}
                className={cx({
                  // 'mt-4': !isAddMethodologyEnabled,
                  // 'mt-5': isAddMethodologyEnabled,
                  [classes.addMethodologyButtonGrey]:
                    isAddMethodologyEnabled && (values.projectMethodologyName || '').length < 3,
                  [classes.addMethodologyButton]:
                    (values.projectMethodologyName || '').length >= 3 || !isAddMethodologyEnabled,
                })}
                onClick={() => {
                  handleAddNewMethodologyClick(values.projectMethodologyName, setFieldValue);
                }}
              >
                {isAddMethodologyEnabled ? (
                  isSubmittingAddProjectMethodology ? (
                    <Spinner size={24} />
                  ) : (
                    <DoneIcon />
                  )
                ) : (
                  <AddIcon />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <CustomSelect
                label="Location Type"
                className="mb-0"
                name="locationType"
                type="select"
                placeholder=""
                required
                selectOptions={PROJECT_LOCATION_TYPES_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <MaterialDate
                  name="startDate"
                  label="Start Date"
                  className="mb-0"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>

              <Grid item xs={6}>
                <MaterialDate
                  name="endDate"
                  type="date"
                  label="End Date"
                  className="mb-0"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isScheduled()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              {[PROJECT_LOCATION_TYPES.hybrid, PROJECT_LOCATION_TYPES.virtual].includes(
                values.locationType,
              ) && (
                <TextInput
                  name="zoomLink"
                  type="string"
                  label="Zoom Link"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                />
              )}
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <MaterialTime
                  name="startTime"
                  label="Start Time"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>

              <Grid item xs={6}>
                <MaterialTime
                  name="endTime"
                  label="End Time"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isScheduled()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              {[PROJECT_LOCATION_TYPES.hybrid, PROJECT_LOCATION_TYPES.in_person].includes(
                values.locationType,
              ) && (
                <TextInput
                  name="venue"
                  // type="string"
                  label="Venue"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                />
              )}
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <div>
                  <Typography className={cx(classes.checkboxLabel, 'mb-1')}>Free</Typography>
                  <CustomCheckbox name="isFree" label="" />
                </div>
              </Grid>
              {!values.isFree && (
                <>
                  <Grid item xs={6}>
                    <TextInput
                      name="contractValue"
                      type="number"
                      label="Amount"
                      className="mb-1"
                      inputProps={{
                        max: deal?.amountLeft ?? 999999,
                        min: 0,
                      }}
                      required
                      additionalStyles={{
                        container: classes.selectContainer,
                        label: classes.inputLabel,
                      }}
                    />
                    {project ? (
                      <Typography className={cx(classes.inputLabel)}>
                        {deal
                          ? `${formatPrice(deal.amountLeft + formInitialValues.contractValue)}`
                          : '$0'}{' '}
                        remaining
                      </Typography>
                    ) : (
                      <Typography className={cx(classes.inputLabel)}>
                        {deal ? `${formatPrice(deal.amountLeft)}` : '$0'} remaining
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <TextArea
            name="notes"
            type="text"
            label="Notes"
            multiline
            fullWidth
            rows={4}
            className="mb-4"
            additionalStyles={{ label: classes.inputLabel }}
          />

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <CustomSelect
                label="Calendar Event Recurrence"
                className="mb-0"
                name="calendarEventRecurrenceType"
                type="select"
                placeholder=""
                required
                selectOptions={PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button
                className={classes.saveButton}
                disabled={isSubmitting || !deal}
                onClick={submitForm}
              >
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddProjectToDealForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddProjectToDealForm;
