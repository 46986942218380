import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { Form, Formik } from 'formik';
//validation library
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useSelector, useDispatch } from 'react-redux';
import { selectProject, selectJob } from 'redux/selectors/staffing/getProject';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { addJobToProject } from 'redux/actions/staffingActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import { generateRandomId } from 'utils/generateRandomId';
import { JOB_ROLE_SELECT_OPTIONS, SEAT_SELECT_OPTIONS } from 'constants/staffing';

import TextInput from 'components/CustomFormComponents/TextInput';
import ButtonSpinner from 'components/ButtonSpinner';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import CustomCheckbox from 'components/CustomFormComponents/CustomCheckbox';
import MaterialDate from 'components/CustomFormComponents/MaterialDate';
import TextArea from 'components/CustomFormComponents/TextArea';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  addCompanyButton: {
    height: 40,
    width: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    marginLeft: 5,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  addCompanyButtonGrey: {
    height: 40,
    width: 40,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    marginLeft: 5,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
      color: '#fff',
    },
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },

  inputLabel: {
    lineHeight: '30px',
    fontSize: 12,
    fontWeight: '300',
  },
  input: {
    // height: 40,
  },

  selectContainer: {
    marginBottom: 0,
  },
  jobRoleSelectContainer: {
    marginBottom: 14,
  },
}));

const AddJobToDealProjectForm = props => {
  const { closeDialog } = props;

  const classes = useStyles();

  const { dealProjectToUpdateId, jobToUpdateId } = useStaffingSelector();

  const dispatch = useDispatch();

  const getProject = selectProject();
  const getJob = selectJob();

  //Get the Project Information
  const project = useSelector(state => getProject(state, dealProjectToUpdateId));

  //Get the Job Info from the Store
  const job = useSelector(state => getJob(state, dealProjectToUpdateId, jobToUpdateId));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formInitialValues = {
    jobRole: job ? job.jobRole : '',
    jobName: job ? job.jobName : '',
    isLead: job ? job.isLead : false,
    seats: job ? job.seats : '',
    isFixedFee: job ? (job.isFixedFee ?? true) : true,
    fixedRate: job ? (job.fixedRate ?? null) : null,
    maxHours: job ? job.maxHours : null,
    hourlyRate: job ? job.hourlyRate : null,
    startDate: job ? job.startDate : project?.startDate,
    endDate: job ? job.endDate : project?.endDate,
    description: job ? job.description : '',
    disableJobInvite: job ? (job.disableJobInvite ?? false) : false,
  };

  const formSchema = Yup.object().shape({
    jobRole: Yup.string().required('Required'),
    jobName: Yup.string().required('Required'),
    disableJobInvite: Yup.boolean(),
    isLead: Yup.boolean().test(
      'lead-already-assigned-for-role',
      'Lead already set on different job for selected job role',
      function (value) {
        return (
          value === false ||
          (value === true && !project.jobRolesAlreadyMarkedAsLead.includes(this.parent.jobRole)) ||
          // make sure validation isn't triggered when editing a job where isLead was previously set...
          (value === true &&
            project.jobRolesAlreadyMarkedAsLead.includes(this.parent.jobRole) &&
            job?.jobRole === this.parent.jobRole)
        );
      },
    ),
    seats: Yup.number().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End Date can't be set before the Start date")
      .required('Required'),
    isFixedFee: Yup.boolean(),
    maxHours: Yup.number()
      .typeError('maxHours must be a number')
      .when('isFixedFee', {
        is: false,
        then: Yup.number().typeError('Reuired').required('Required').positive('Must be positive'), //enable validation when isFixedFee is off
        otherwise: Yup.number().nullable().notRequired(), // No validation when isFixedFee is true
      }),
    hourlyRate: Yup.number().when('isFixedFee', {
      is: false,
      then: Yup.number().typeError('Required').required('Required').positive('Must be positive'), //enable validation when isFixedFee is off
      otherwise: Yup.number().nullable().notRequired(), // No validation when isFixedFee is true
    }),
    fixedRate: Yup.number().when('isFixedFee', {
      is: true,
      then: Yup.number().typeError('Required').required('Required').positive('Must be positive'), //enable validation when isFixedFee is on
      otherwise: Yup.number().nullable().notRequired(), // No validation when isFixedFee is true
    }),
    description: Yup.string().required('Required'),
  });

  const handleFormSubmit = values => {
    setIsSubmitting(true);

    const jobId = jobToUpdateId || generateRandomId();
    dispatch(
      addJobToProject(dealProjectToUpdateId, jobId, { ...values }, () => {
        setIsSubmitting(false);
        closeDialog();
      }),
    );
  };

  const onFormChange = (values, setFieldValue) => {
    console.log('values', values);
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={async values => {
        handleFormSubmit(values);
      }}
    >
      {({
        submitForm,
        resetForm,
        values,
        setFieldValue,
        errors,
        touched,
        submitCount,
        isSubmitting,
      }) => (
        <Form style={{ paddingBottom: 20 }}>
          {/* Listens to form values and updates any dependant values */}
          <FormListener values={values} setFieldValue={setFieldValue} />

          <TextInput
            name="jobName"
            type="string"
            label="Job Name"
            className="mb-4"
            required
            additionalStyles={{ label: classes.inputLabel }}
            // additionalStyles={{ input: classes.input }}
          />

          <CustomSelect
            label="Job Role"
            className="mb-4"
            name="jobRole"
            type="select"
            placeholder=""
            customStyles={{
              container: classes.jobRoleSelectContainer,
              label: classes.inputLabel,
            }}
            selectOptions={JOB_ROLE_SELECT_OPTIONS}
          ></CustomSelect>

          <Grid container spacing={5} className="mb-4">
            <Grid item xs={6} container alignItems="flex-end">
              <CustomCheckbox name="isLead" label="Lead Role" required />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                label="Seats"
                name="seats"
                type="select"
                placeholder=""
                required
                selectOptions={values.isLead ? [{ name: '1', value: 1 }] : SEAT_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5} className="mb-4">
            <Grid item xs={6} container alignItems="flex-end">
              <CustomCheckbox name="isFixedFee" label="Fixed Fee" required />
              <CustomCheckbox name="disableJobInvite" label="Disable Job Invite Emails" />
            </Grid>
            {values.isFixedFee ? (
              <Grid item xs={6} className="pb-0 pt-0">
                <TextInput
                  name="fixedRate"
                  type="number"
                  label="Job Fee"
                  required
                  additionalStyles={{ label: classes.inputLabel }}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={3} container>
                  <TextInput
                    name="maxHours"
                    type="number"
                    label="Max Hours"
                    required
                    additionalStyles={{ label: classes.inputLabel }}
                  />
                </Grid>
                <Grid item xs={3} container>
                  <TextInput
                    name="hourlyRate"
                    type="number"
                    label="Hourly Rate"
                    required
                    additionalStyles={{ label: classes.inputLabel }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <MaterialDate name="startDate" label="Start Date" required />
            </Grid>

            <Grid item xs={6}>
              <MaterialDate name="endDate" type="date" label="End Date" required />
            </Grid>
          </Grid>

          <TextArea
            name="description"
            type="text"
            label="Description (include services & deliverables)"
            multiline
            fullWidth
            required
            rows={4}
            className="mb-4"
          />

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const FormListener = ({ values: { isLead }, setFieldValue }) => {
  useEffect(() => {
    if (isLead) {
      setFieldValue('seats', 1);
    }
  }, [isLead]);

  return null;
};

export default AddJobToDealProjectForm;
