import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import * as Sentry from '@sentry/react';

import { UPDATE_STAFFING_STATE } from '../types/staffingTypes';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import { generateRandomId } from 'utils/generateRandomId';

import { clamp, map } from 'lodash';

import moment from 'moment';
import {
  PROJECT_STATUS_STATES,
  DEAL_STATUS_STATES,
  PROJECTS_CHECKLISTS_SEEDS,
  PROJECT_SERVICES,
  STAFF_TYPES,
} from 'constants/staffing';
import { showAppOverlaySpinner } from './facilitatorSessionActions';

// ----------------------------- DEALS ----------------------------- //

/**
 * Sett Deal Insights dialog open or closed
 * @param {boolean} isOpenCreateDealDialog
 * @param {String} dealInsightId
 */
export const openDealInsightsDialog = (isOpenDealInsightsDialog, dealInsightId = null) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenDealInsightsDialog, dealInsightId },
  };
};

/**
 * Set Create/Edit Deal dialog open or closed
 * @param {boolean} isOpenCreateDealDialog
 * @param {String} dealToUpdateId
 */
export const openCreateDealDialog = (isOpenCreateDealDialog, dealToUpdateId = null) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenCreateDealDialog, dealToUpdateId },
  };
};

/**
 * Set Create/Edit Deal Project dialog open or closed
 * @param {boolean} isOpenAddProjectToDealDialog
 * @param {String} dealToUpdateId
 */
export const openAddProjectToDealDialog = (
  isOpenAddProjectToDealDialog,
  dealToUpdateId = null,
  projectToUpdateId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: {
      isOpenAddProjectToDealDialog,
      dealToUpdateId,
      projectToUpdateId,
    },
  };
};

/**
 * Set Create/Edit Checklist dialog open or closed
 * @param {boolean} isOpenStaffingChecklistModal
 * @param {String} staffingChecklistProjectId
 * @param {String} staffingChecklistCategory
 */
export const openStaffingChecklistModal = (
  isOpenStaffingChecklistModal,
  staffingChecklistProjectId = '',
  staffingChecklistCategory = '',
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: {
      isOpenStaffingChecklistModal,
      staffingChecklistProjectId,
      staffingChecklistCategory,
    },
  };
};

export const setDealsFilterPayload = filterPayload => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { ...filterPayload },
  };
};

/**
 * Create Deal
 *
 * @param {Object} dealData,
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const createDeal =
  (dealData, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const { uid } = getState().firebase.auth;

    const dealRef = db().collection(`deals`).doc();

    db()
      .collection('deals')
      .doc(dealRef.id)
      .set({
        ...dealData,
        id: dealRef.id,
        createdAt: db.FieldValue.serverTimestamp(),
        status: DEAL_STATUS_STATES.INITIALIZING,
        statusUpdatedAt: Date.now().valueOf(),
        updatedBy: uid,
        logs: [],
      })
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating Deal'));
      });
  };

/**
 * Update Deal
 *
 * @param {String} id
 * @param {Object} dealData
 * @param {Function} successCb
 */
export const updateDeal =
  (id, dealData, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const { uid } = getState().firebase.auth;

    db()
      .doc(`deals/${id}`)
      .set(
        {
          ...dealData,
          updatedAt: db.FieldValue.serverTimestamp(),
          updatedBy: uid,
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error updating Deal Info'));
      });
  };

/**
 * Delete Deal
 *
 * @param {String} id
 * @param {Object} dealData
 * @param {Function} successCb
 */
export const deleteDeal =
  (id, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`deals/${id}`)
      .delete()
      .then(() => {
        if (successCb) successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error deleting deal'));
      });
  };

/**
 * Create Company
 *
 * @param {Object} companyName,
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const createCompany =
  (companyName, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const companyRef = db().collection(`companies`).doc();

    db()
      .collection('companies')
      .doc(companyRef.id)
      .set({
        companyName: companyName,
        id: companyRef.id,
        createdAt: db.FieldValue.serverTimestamp(),
      })
      .then(() => {
        if (successCb) {
          successCb(companyRef.id);
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating Company'));
      });
  };

// ----------------------------- END OF DEALS ----------------------------- //

// ----------------------------- CONTRACTORS ----------------------------- //

/**
 * Sett Project Insights dialog open or closed
 * @param {boolean} isOpenProjectInsightsDialog
 * @param {String} projectInsightId
 */
export const openProjectInsightsDialog = (isOpenProjectInsightsDialog, projectInsightId = null) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenProjectInsightsDialog, projectInsightId },
  };
};

/**
 * Set Create/Edit Contractor dialog open or closed
 * @param {boolean} isOpenCreateContractorDialog
 * @param {String} contractorToUpdateId
 */
export const openCreateContractorDialog = (
  isOpenCreateContractorDialog,
  contractorToUpdateId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenCreateContractorDialog, contractorToUpdateId },
  };
};

/**
 * Set Add Contractor To Job Dialog open or closed
 * @param {boolean} isOpenAddToJobDialog
 * @param {String} contractorToUpdateId
 */
export const openAddContractorToJobDialog = (isOpenAddToJobDialog, contractorToUpdateId) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenAddToJobDialog, contractorToUpdateId },
  };
};

/**
 * Set Contractor Filter Params Payload
 * @param {Object} filterPayload
 */
export const setContractorFilterPayload = filterPayload => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { ...filterPayload },
  };
};

/**
 * Set Schedules Filter Params Payload
 * @param {Object} filterPayload
 */
export const setSchedulesFilterPayload = filterPayload => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { ...filterPayload },
  };
};

/**
 * Create Contractor User
 *
 * @param {Object} contractorData,
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const createContractorUser =
  (contractorData, hasCb, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const contractorRef = db().collection(`contractors`).doc();

    db()
      .collection('contractors')
      .doc(contractorRef.id)
      .set({
        ...contractorData,
        id: contractorRef.id,
        createdAt: db.FieldValue.serverTimestamp(),
        isActive: true,
      })
      .then(() => {
        if (hasCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create contractor user'));
      });
  };

/**
 * Update Contractor User
 *
 * @param {String} userId
 * @param {Object} contractorData
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const updateContractorUser =
  (userId, contractorData, hasCb, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`contractors/${userId}`)
      .set(
        {
          ...contractorData,
          updatedAt: db.FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(() => {
        if (hasCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error updating Staff Info'));
      });
  };

/**
 * Delete Contractor User
 *
 * @param {String} contractorId
 * @param {Function} successCb
 */
export const deleteContractorUser =
  (contractorId, successCb) =>
  async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const contractorRef = db().doc(`contractors/${contractorId}`);

    db()
      .runTransaction(transaction => {
        return transaction.get(contractorRef).then(async contractorDoc => {
          if (!contractorDoc.exists) {
            throw 'Staff document does not exist!';
          }

          const contractorData = contractorDoc.data();

          if (contractorData.userId) {
            const userRef = db().doc(`users/${contractorData.userId}`);

            transaction.update(userRef, {
              staffingUser: false,
              vcContractor: false,
              contractorId: db.FieldValue.delete(),
            });
          }

          transaction.delete(contractorRef);
        });
      })
      .then(() => successCb && successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error deleting Staff User'));
      });
  };

// ----------------------------- PROJECTS ----------------------------- //

/**
 * Set Add Job to Project dialog open or closed
 * @param {boolean} isOpenAddJobToProjectDialog
 * @param {String} projectToUpdateId
 * @param {String} jobToUpdateId
 */
export const openAddJobToProjectDialog = (
  isOpenAddJobToProjectDialog,
  projectToUpdateId = null,
  jobToUpdateId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenAddJobToProjectDialog, projectToUpdateId, jobToUpdateId },
  };
};

/**
 * Set Add Job to Project dialog open or closed
 * @param {boolean} isOpenAddJobToDealProjectDialog
 * @param {String} dealProjectToUpdateId
 * @param {String} jobToUpdateId
 */
export const openAddJobToDealProjectDialog = (
  isOpenAddJobToDealProjectDialog,
  dealProjectToUpdateId = null,
  jobToUpdateId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: {
      isOpenAddJobToDealProjectDialog,
      dealProjectToUpdateId,
      jobToUpdateId,
    },
  };
};

/**
 * Set Clone Job open/closed
 * @param {boolean} isOpenCloneJobDialog
 * @param {String} projectToUpdateId
 * @param {String} jobToCloneId
 */
export const openCloneJobDialog = (
  isOpenCloneJobDialog,
  projectToUpdateId = null,
  jobToCloneId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: {
      isOpenCloneJobDialog,
      projectToUpdateId,
      jobToUpdateId: jobToCloneId,
    },
  };
};

/**
 * Set Manage Contractors Dialog open or closed
 * @param {boolean} isOpenManageContractorDialog
 * @param {String} projectToUpdateId
 * @param {String} jobToUpdateId
 */
export const openManageContractorsDialog = (
  isOpenManageContractorDialog,
  projectToUpdateId = null,
  jobToUpdateId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenManageContractorDialog, projectToUpdateId, jobToUpdateId },
  };
};

/**
 * Create Project Methodology
 *
 * @param {Object} title,
 * @param {Function} successCb
 */
export const createProjectMethodology =
  (title, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const methodologyRef = db().collection(`projectMethodologies`).doc();

    db()
      .collection('projectMethodologies')
      .doc(methodologyRef.id)
      .set({
        title: title,
        id: methodologyRef.id,
        createdAt: db.FieldValue.serverTimestamp(),
      })
      .then(() => {
        if (successCb) {
          successCb(methodologyRef.id);
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating Project Methodology'));
      });
  };

/**
 * Add Checklist Items to Project
 *
 * @param {String} projectId
 * @param {String} checklistId
 * @param {Object} checklistData,
 * @param {Function} successCb
 */
export const addChecklistToProject =
  (projectId, checklistId, checklistData, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`projects/${projectId}`)
      .set(
        {
          checklists: {
            [checklistId]: {
              id: checklistId,
              ...checklistData,
            },
          },
          updatedAt: db.FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding/updating Checklist to project'));
      });
  };

/**
 * Create Project
 *
 * @param {Object} projectData,
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const createProject =
  (projectData, hasCb, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const { uid } = getState().firebase.auth;

    const projectRef = db().collection(`projects`).doc();

    const checklists = [];

    map(Object.keys(PROJECTS_CHECKLISTS_SEEDS), category => {
      map(PROJECTS_CHECKLISTS_SEEDS[category], (item, index) => {
        checklists.push({
          id: generateRandomId(),
          category: category,
          completed: false,
          dueDate: moment(projectData.startDate).subtract(item.leadTime, 'days').valueOf(),
          title: item.title,
          order: index,
        });
      });
    });

    // TODO: this should be a batch operation in case either deal update or project creation fails

    //Update the Deals collection with the new Project id
    if (projectData.dealId) {
      db()
        .doc(`deals/${projectData.dealId}`)
        .set(
          {
            projects: db.FieldValue.arrayUnion(projectRef.id),
            updatedAt: db.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
    }

    //Add the New Project Document
    db()
      .collection('projects')
      .doc(projectRef.id)
      .set({
        ...projectData,
        id: projectRef.id,
        isCreatingAttendeeAssets: false,
        jobs: {},
        createdAt: db.FieldValue.serverTimestamp(),
        status: PROJECT_STATUS_STATES.INITIALIZING,
        statusUpdatedAt: Date.now().valueOf(),
        checklists: checklists.reduce((obj, item) => ({ ...obj, [item.id]: { ...item } }), {}), //convert the checklists array
        updatedBy: uid,
        logs: [],
        assets: {},
        onStatusFired: [],
        hasSyncedAttendees: false,
      })
      .then(() => {
        if (hasCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating Project'));
      });
  };

/**
 * Update Project
 *
 * @param {String} id
 * @param {Object} projectData
 * @param {Function} successCb
 */
export const updateProject =
  (id, projectData, successCb, previousDealId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const { uid } = getState().firebase.auth;

    const projectRef = db().doc(`projects/${id}`);

    db()
      .runTransaction(transaction => {
        return transaction.get(projectRef).then(async projectDoc => {
          if (!projectDoc.exists) {
            throw 'Project document does not exist!';
          }

          const project = projectDoc.data();

          //If Updating the DealId, use a Transaction to update the previous Deal...
          //and update the New Deal..
          if (project.dealId && previousDealId) {
            //add the Project to the new deal
            const newDealRef = db().doc(`deals/${projectData.dealId}`);
            transaction.update(newDealRef, {
              projects: db.FieldValue.arrayUnion(projectRef.id),
            });

            //remove the Project from the previous deal
            const previousDealRef = db().doc(`deals/${previousDealId}`);
            transaction.update(previousDealRef, {
              projects: db.FieldValue.arrayRemove(projectRef.id),
            });
          }

          //update the Project
          transaction.update(projectRef, {
            ...projectData,
            updatedAt: db.FieldValue.serverTimestamp(),
            updatedBy: uid,
          });
        });
      })
      .then(() => successCb && successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error updating Project Info'));
      });
  };

/**
 * Delete Project Data
 *
 * @param {String} projectId
 * @param {Function} successCb
 */
export const deleteProject =
  (projectId, dealId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const batch = db().batch();
    const projectRef = db().doc(`projects/${projectId}`);
    const dealRef = db().doc(`deals/${dealId}`);

    batch.delete(projectRef);

    batch.update(dealRef, {
      projects: db.FieldValue.arrayRemove(projectId),
    });

    batch
      .commit()
      .then(() => successCb && successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error deleting project'));
      });
  };

/**
 * Add Jobs to Project
 *
 * @param {String} projectId
 * @param {String} jobId
 * @param {Object} jobData,
 * @param {Function} successCb
 */
export const addJobToProject =
  (projectId, jobId, jobData, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`projects/${projectId}`)
      .set(
        {
          jobs: {
            [jobId]: {
              jobId,
              ...jobData,
            },
          },
          updatedAt: db.FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding Job to project'));
      });
  };

/**
 * Delete Job Data
 *
 * @param {String} projectId
 * @param {String} jobId
 * @param {Function} successCb
 */
export const deleteJob =
  (projectId, jobId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const path = `jobs.${jobId}`;

    db()
      .doc(`projects/${projectId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error deleting Job from project'));
      });
  };

/**
 * Add Contactor to Job
 *
 * @param {String} projectId
 * @param {String} jobId
 * @param {String} contractorId
 * @param {Function} successCb
 */
export const addContractorToJob =
  (projectId, jobId, contractorId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const { email } = getState().firebase.auth;
    const { contractors } = getState().firestore.data;

    const isContractor = contractors[contractorId].staffType === STAFF_TYPES.Contractor;

    db()
      .doc(`projects/${projectId}`)
      .set(
        {
          jobs: {
            [jobId]: {
              assignees: {
                [contractorId]: {
                  contractorId: contractorId,
                  status: isContractor ? 'pending' : 'paid',
                  assignedDate: db.FieldValue.serverTimestamp(),
                  assignedBy: email,
                },
              },
            },
          },
          updatedAt: db.FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding Staff to Job'));
      });
  };

/**
 * Delete/Unassign Contactor from Job
 *
 * @param {String} projectId
 * @param {String} jobId
 * @param {String} contractorId
 * @param {Function} successCb
 */
export const removeContractorFromJob =
  (projectId, jobId, contractorId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const path = `jobs.${jobId}.assignees.${contractorId}`;

    db()
      .doc(`projects/${projectId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing Staff from Project'));
      });
  };

/**
 * Update Assignee Info on Job in Project
 *
 * @param {String} projectId
 * @param {String} jobId
 * @param {String} contractorId
 * @param {Object} values
 * @param {Function} successCb
 */
export const updateAssigneeInJob =
  (projectId, jobId, contractorId, values, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`projects/${projectId}`)
      .set(
        {
          jobs: {
            [jobId]: {
              assignees: {
                [contractorId]: {
                  ...values,
                  updatedAt: db.FieldValue.serverTimestamp(),
                },
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding Staff to Job'));
      });
  };

/**
 * Calls a cloud function to re-sync the calendar entry from the project data
 * @param {string} projectId
 * @param {Function} successCb
 * @param {Function=} errorCb
 */
export const reSyncCalendarEntry =
  (projectId, successCb, errorCb) =>
  async (dispatch, getState, { getFirebase }) => {
    getFirebase()
      .functions()
      .httpsCallable('projects-onCall_reSyncCalendar')({ projectId })
      .then(() => successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error Re-Syncing calendar'));
        if (errorCb) errorCb();
      });
  };

/**
 * Calls a cloud function to re-sync the calendar entry from the project data
 * @param {string} projectId
 * @param {Function} successCb
 * @param {Function=} errorCb
 */
export const reSyncAttendeesListFromSheet =
  (projectId, successCb, errorCb) =>
  async (dispatch, getState, { getFirebase }) => {
    getFirebase()
      .functions()
      .httpsCallable('projects-onCall_syncAttendeeList')({ projectId })
      .then(() => successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error Syncing Attendees List'));
        if (errorCb) errorCb();
      });
  };

/**
 * Calls a cloud function to re-sync the project messaging config
 * @param {string} projectId
 * @param {Function} successCb
 * @param {Function=} errorCb
 */
export const reSyncAttendeeMessagingConfig =
  (projectId, successCb, errorCb) =>
  async (dispatch, getState, { getFirebase }) => {
    getFirebase()
      .functions()
      .httpsCallable('projects-onCall_refreshMessagingConfig')({ projectId })
      .then(() => successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error syncing messaging config'));
        if (errorCb) errorCb();
      });
  };

/**
 * Calls a cloud function to override the project messaging config and
 * another to re-sync the project messaging config to the project record
 * @param {string} projectId
 * @param {Function} successCb
 * @param {Function=} errorCb
 */
export const overrideDefaultAttendeeMessagingConfig =
  (projectId, successCb, errorCb) =>
  async (dispatch, getState, { getFirebase }) => {
    getFirebase()
      .functions()
      .httpsCallable('projects-onCall_overrideMessagingSchedule')({ projectId })
      .then(() => {
        getFirebase()
          .functions()
          .httpsCallable('projects-onCall_refreshMessagingConfig')({ projectId })
          .then(() => successCb())
          .catch(err => {
            console.error(err);
            dispatch(
              enqueueErrorSnackbar('Error syncing messaging config to project after overriding'),
            );
            if (errorCb) errorCb();
          });
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error overriding messaging config'));
        if (errorCb) errorCb();
      });
  };

// ----------------------------- CONTRACTOR DASHBOARD ----------------------------- //

/**
 * Set ProjectRoleAction dialog open or closed
 * @param {boolean} isOpenProjectRoleActionDialog
 * @param {String} projectRoleActionDialogType
 * @param {String} jobToUpdateId
 * @param {String} projectId
 */
export const openProjectRoleActionDialog = (
  isOpenProjectRoleActionDialog,
  projectRoleActionDialogType = '',
  jobToUpdateId = null,
  projectId = null,
) => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: {
      isOpenProjectRoleActionDialog,
      projectRoleActionDialogType,
      jobToUpdateId,
      projectId,
    },
  };
};

/**
 * Set ProjectRoleActionFeedback dialog open or closed
 * @param {boolean} isOpenProjectRoleActionFeedbackDialog
 */
export const openProjectRoleActionFeedbackDialog = isOpenProjectRoleActionFeedbackDialog => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { isOpenProjectRoleActionFeedbackDialog },
  };
};

/**
 * Sends Contract Reminder
 * @param {String} contractorEmail
 * @param {String} helloSignRequestId
 */
export const sendContractReminder =
  (contractorEmail, helloSignRequestId, isContractor = false) =>
  dispatch => {
    const functions = firebase.functions();

    dispatch(showAppOverlaySpinner(true));

    functions
      .httpsCallable('staffing-resend_contract')({
        email: contractorEmail,
        signatureRequestId: helloSignRequestId,
      })
      .then(response => {
        dispatch(showAppOverlaySpinner(false));

        if (isContractor) {
          dispatch(enqueueSuccessSnackbar(`Contract resent to your email`));
        } else {
          dispatch(enqueueSuccessSnackbar(`Contract resent to ${contractorEmail}`));
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        dispatch(showAppOverlaySpinner(false));
        dispatch(enqueueErrorSnackbar('Error resending Contract'));
      });
  };

/**
 * Creates and sends a contract for a Contractor
 * @param {String} projectId
 * @param {String} contractorId
 * @param {String} jobId
 * @param {String} contractorEmail
 */
export const createAndSendContract =
  (projectId, contractorId, jobId, contractorEmail) => dispatch => {
    const functions = firebase.functions();

    dispatch(showAppOverlaySpinner(true));

    functions
      .httpsCallable('staffing-create_and_send_contract')({
        projectId: projectId,
        contractorId: contractorId,
        jobId: jobId,
      })
      .then(response => {
        dispatch(showAppOverlaySpinner(false));
        dispatch(enqueueSuccessSnackbar(`Contract sent to ${contractorEmail}`));
      })
      .catch(err => {
        Sentry.captureException(err);
        dispatch(showAppOverlaySpinner(false));
        dispatch(enqueueErrorSnackbar('Error creating and sending contract'));
      });
  };

// ----------------------------- SCHEDULES PAGE ----------------------------- //
export const changeSelectedMonth = monthIndex => {
  return {
    type: UPDATE_STAFFING_STATE,
    payload: { selectedScheduleMonth: clamp(parseInt(monthIndex), 0, 11) }, //Limits the Selected Month between range of 0 and 11
  };
};
