import { createSelector } from 'reselect';
import { isLoaded } from 'react-redux-firebase';
import { map, compact, find, includes, upperCase } from 'lodash';

import { getContractorJobs, filterOutContractors } from './getContractor';
import { SCHEDULES_MONTHS_OPTIONS } from '../../../constants/staffing';

const projectsSelector = (state, id) => state.firestore.data.projects;
const contractorsSelector = (state, id) => state.firestore.data.contractors;
const staffingSelector = state => state.staffing;

/**
 * Selector for retrieving Schedules
 */
export const selectSchedules = () => {
  return createSelector(
    [projectsSelector, contractorsSelector, staffingSelector],
    (projects, contractors, staffing) => {
      if (!isLoaded(projects) || !isLoaded(contractors)) return { isLoaded: false };

      const {
        selectedScheduleMonth = new Date().getMonth(), //Defaults the Selected Month to the Current Month
      } = staffing;

      const schedules = [];

      const filteredContractors = map(contractors, contractor => {
        return filterOutContractors(contractor, staffing);
      });

      //Map through All Contractors, and get the Jobs for each contractor
      map(compact(filteredContractors), contractor => {
        const contractorJobs = getContractorJobs(projects, contractor);
        map(contractorJobs, job => {
          //Check if the Project Start Date Month is the Selected Month
          //And only Display Jobs (accepted/signed) by the Contractor
          if (
            new Date(job.projectStartDate).getMonth() === selectedScheduleMonth &&
            includes(['accepted', 'signed', 'paid'], job.status)
          ) {
            schedules.push([
              `${contractor.displayName}  ${contractor.staffType ? `(${upperCase(contractor.staffType[0])})` : ''}`,
              `${job.projectCompanyName || job.clientName || ''} : ${job.eventName || ''}`,
              new Date(job.projectStartDate),
              new Date(job.projectEndDate),
            ]);
          }
        });
      });

      const currentMonth = find(SCHEDULES_MONTHS_OPTIONS, ['monthIndex', selectedScheduleMonth]);

      return {
        schedules,
        currentMonth,
        selectedScheduleMonthIndex: selectedScheduleMonth,
        isLoaded: true,
      };
    },
  );
};
